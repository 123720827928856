<template>
  <div class='container'>
    <div class="content">
      <div class="option">
        <div class="title">称谓</div>
        <div class="select">
          <input type="text" v-model="listQuery.parent_relationship" placeholder="请选择成员称谓" @click="isShow=true" readonly>
          <van-icon class="arrow" name="arrow-down" size="5.3333vw"/>
        </div>
      </div>
      <div class="option">
        <div class="title">姓名</div>
        <input type="text" v-model="listQuery.name" placeholder="请输入成员姓名">
      </div>
      <div class="option">
        <div class="title">年龄</div>
        <input type="number" v-model="listQuery.age" placeholder="请输入您的年龄">
      </div>
      <div class="option">
        <div class="title">职业</div>
        <input type="text" v-model="listQuery.occupation" placeholder="请输入成员职业">
      </div>
      <div class="option">
        <div class="title">个性特点</div>
        <input type="text" v-model="listQuery.characteristics" placeholder="请输入个性特点">
      </div>
      <div class="option">
        <h2>你对他的喜爱程度</h2>
        <div class="lives">
          <div :class="['live',listQuery.like_level===index?'active':'']" v-for="(item,index) in live" :key="index" @click="liveChange(index)">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <button @click="submit">保存</button>
    </div>
    <van-popup v-model="isShow" position="bottom" :style="{ height: '40%' }" >
      <van-picker
        title="选择称谓"
        show-toolbar
        :columns="familyList"
        @confirm="onConfirm"
        @cancel="isShow=false"
      />
    </van-popup>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {profileUpdateV2} from '@/api/student.js'
export default {
  data(){
    return{
      live:['喜爱','较喜爱','无所谓','不喜爱'],
      familyList:['父亲','母亲','哥哥','弟弟','姐姐','妹妹','爷爷','奶奶'],
      isShow:false,
      listQuery:{
          parent_relationship: "",
          name: "",
          age: null,
          occupation: "",
          characteristics: "",
          like_level: 0,
      },
      formData: {
          id: '',
          sn: '', // 学籍号
          grade: '', // 年级
          studyClass: '', // 班级
          gender: '', // 性别
          area: '', // 校区
          birthday: '', // 出生日期
          nation: '', // 民族
          birth_place: '', // 籍贯
          situation: '', // 学习情况
          interest: [], // 兴趣
          interest_other: '', // 兴趣
          home_address: '', // 家庭地址
          health_status: '', // 健康状况
          psychological_diagnsis: '', // 心理诊断记录
          past_medical_history: [], // 过往身体病史
          past_medical_history_other: '', // 过往身体病史
          parent_relationship: '', // 父母关系
          family_atmosphere: '', // 家庭气氛
          family_economy: '', // 家庭经济情况
          other: '', // 其他情况
          f_name: '', // 父亲名字
          f_age: '', // 年龄
          f_occupation: '', // 职业
          f_characteristics: '', // 个人特点
          f_like_level: '', // 喜爱程度 1 喜欢 2 不喜欢
          m_name: '', // 母亲名字
          m_age: '', // 年龄
          m_occupation: '', // 职业
          m_characteristics: '', // 个人特点
          m_like_level: '', // 喜爱程度 1 喜欢 2 不喜欢
          study: [], // 学习经历
          event: [], // 重大生活事件
          home: [], // 家庭成员
          province: '', // 省
          city: '', // 市
          county: '' // 区
        },
    }
  },
  async created(){
    await this.getDetail()
    this.getInfo()
  },
  computed:{
    ...mapState(['student']),
  },
  methods:{
    getInfo(){
      const id = this.$route.query.homeId
      if(id){
        console.log(this.formData.home.filter(item=>item.id!=id));
        this.listQuery=this.formData.home.filter(item=>item.id==id)[0]
      }
    },
    async getDetail(){
      const {id}=this.$route.query
      await this.$store.dispatch('getStudentDetail',id)
      this.formData=this.student
    },
    onConfirm(val){
      this.listQuery.parent_relationship=val
      this.isShow=false
    },
    async submit(){
      if(!this.listQuery.parent_relationship){
        this.$toast.fail('请选择成员称谓')
        return
      }
      if(!this.listQuery.name){
        this.$toast.fail('成员姓名不能为空')
        return
      }
      if(!this.listQuery.age){
        this.$toast.fail('成员年龄不能为空')
        return
      }
      if(!this.listQuery.occupation){
        this.$toast.fail('成员职业不能为空')
        return
      }
      if(!this.listQuery.characteristics){
        this.$toast.fail('个性特点不能为空')
        return
      }
      const formData=this.formData
      const id=this.$route.query.homeId
      if(id){
        this.formData.home.forEach(item=>{
          if(item.id==id)item=this.listQuery
        })
      }else{
        formData.home.push(this.listQuery)
      }
      const res = await profileUpdateV2(formData)
      this.$toast.success('修改成功')
      this.$router.back()
    },
    liveChange(index){
      this.listQuery.like_level=index
    }
  }
}
</script>
<style lang='less' scoped>
.container{
  padding: 5.3333vw 0 16vw 0;
  .content{
    padding: 0 4vw;
    .option{
      margin-bottom: 4vw;
      .title{
        font-weight: 550;
        margin-bottom: 3.4667vw;
      }
      .select{
        position: relative;
        input{
          padding-right: 10.6667vw;
        }
        .arrow{
          position: absolute;
          top: 50%;
          right: 4vw;
          transform: translateY(-50%);
        }
      }
      .lives{
        margin-top: 4vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .live{
          margin-right: 4vw;
          // padding: 0 4vw;
          flex: 1;
          height: 10.6667vw;
          line-height: 10.6667vw;
          text-align: center;
          background: #F5F7F9;
          &:last-child{
            margin-right: 0;
          }
        }
        .live.active{
          color:#fff;
          background: #5EDBC9;
        }
      }
      input{
        padding: 0 6.1333vw;
        width: 100% ;
        height: 12.2667vw;
        border-radius: 13.3333vw;
        background: #F5F7F9;
      }
    }
  }
  .bottom{
    position: fixed;
    display: flex;
    align-items: center;
    padding: 0 4vw;
    bottom: 0;
    width: 100%;
    height: 16vw;
    button{
      width: 100%;
      color: #fff;
      height:13.8667vw;
      line-height: 13.8667vw;
      font-size: 4vw;
      border-radius: 13.3333vw;
      background: #5EDBC9;
    }
  }
}
</style>